.header-content {
	text-align: center;
	.header-text {
		margin-bottom: 25px;
		font-size: 50px;
		line-height: 57px;
		font-family: 'Exo-Bold';
		padding: 0 50px;
		@media screen and (max-width: 1440px) {
			font-size: 40px;
			line-height: 50px;
		}
		@media screen and (max-width: 1024px) {
			font-size: 35px;
			line-height: 40px;
			padding: 0px 15px;
		}
	}
	.sub-header-text {
		margin: 0 auto 25px;
		max-width: 620px;
		font-size: 25px;
		line-height: 35px;
		// font-weight: $regular;
		margin-bottom: 25px;
		@media screen and (max-width: 1024px) {
			font-size: 15px;
			line-height: 22px;
		}
	}
}

.btn-container {
	@include wrapper;
	text-align: center;
	margin: 100px auto;
	.download-btn {
		// font-weight: $regular;
		padding: 24px 44px 24px 78px;
		background-color: transparent;
		// border: 2px solid $red;
		// color: $red;
		img {
			width: 20px;
			top: 22px;
			left: 44px;
			position: absolute;
			@media screen and (max-width: 1024px) {
				top: 20px;
			}
		}
	}
}

// CElEBRATE WITH US
.column-blocks {
	background-color: #c2d500;
	margin: 0px auto;
	padding: 40px 70px;
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: stretch;
	@media screen and (max-width: 1024px) {
		padding: 40px 40px;
	}
	@media screen and (max-width: 600px) {
		padding: 50px 20px;
	}
	.column-blocks-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: stretch;
	}
	.header-content {
		margin: 0 auto 40px;
		width: 100%;
	}
	.text-align-left {
		text-align: left;
	}
	.text-align-center {
		text-align: center;
	}
	.text-align-right {
		text-align: right;
	}
	.column-blocks-three-col {
		width: calc(33.3333% - 50px);
	}
	.column-blocks-four-col {
		width: calc(25% - 50px);
	}
	.column-blocks-three-col,
	.column-blocks-four-col {
		margin: 0 25px 100px;
		@media screen and (max-width: 1050px) {
			width: calc(50% - 50px);
		}
		@media screen and (max-width: 650px) {
			width: 100%;
			margin: 0 auto 100px;
			text-align: center;
		}
	}
	.column-blocks-two-col {
		width: calc(50% - 200px);
		margin: 0 100px 100px;
		@media screen and (max-width: 1050px) {
			margin: 0 25px 100px;
			width: calc(50% - 50px);
		}
		@media screen and (max-width: 650px) {
			width: 100%;
			margin: 0 auto 100px;
			text-align: center;
		}
	}
	.circle {
		height: 90px;
		width: 90px;
		border-radius: 90px;
		display: flex;
		margin: 0 auto -45px;
		justify-content: center;
		align-items: center;
		z-index: 5;
		@media screen and (max-width: 1140px) {
			height: 70px;
			width: 70px;
			margin: 0 auto -35px;
		}
	}
	.text-box {
		padding: 71px 40px 60px;
		width: 100%;
		height: auto;
		border-radius: 15px;
		background-color: $white;
		color: $bodyText;
		@media screen and (max-width: 1140px) {
			padding: 71px 20px 50px;
		}
		@media screen and (max-width: 650px) {
			padding: 71px 60px 50px;
		}
		@media screen and (max-width: 450px) {
			padding: 71px 20px 50px;
		}
	}
	a {
		text-decoration: none;
	}

	h2 {
		font-size: 28px;
		line-height: 32px;
		font-family: 'Exo-Bold';
		margin-bottom: 25px;
	}
	p {
		font-size: 16px;
		line-height: 27px;
		font-family: 'Fira-Reg';
		padding-bottom: 25px;
		margin-bottom: 25px;
		@media screen and (max-width: 1140px) {
			font-size: 14px;
			line-height: 23px;
		}
	}
}

// CElEBRATE WITH US
.four-column {
	@include wrapper;
	// display: flex;
	// flex-flow: row;
	// justify-content: space-evenly;
	margin-bottom: 80px;
	// @media screen and (max-width: 1260px) {
	// 	flex-flow: row wrap;
	// }
	.header-content {
		width: 100%;
		padding: 10px 30px;
		@media screen and (max-width: 768px) {
			padding: 10px 0px;
		}
		h1 {
			font-size: 50px;
			line-height: 78px;
			margin: 10px auto 25px;
			font-family: 'Exo-Bold';
			color: $navigation;
			max-width: 800px;
			@media screen and (max-width: 1250px) {
				font-size: 40px;
				line-height: 52px;
			}
			@media screen and (max-width: 1250px) {
				font-size: 30px;
				line-height: 40px;
			}
		}
		a {
			text-decoration: none;
			color: $greenBtn;
		}
		p {
			font-size: 21px;
			line-height: 32px;
			font-family: 'Fira-Reg';
			color: $navigation;
			margin: 0 auto 25px;
			max-width: 925px;
			@media screen and (max-width: 1250px) {
				font-size: 18px;
				line-height: 25px;
			}
			@media screen and (max-width: 1250px) {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
	&__container {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		margin-top: 40px;
		@media screen and (max-width: 1260px) {
			flex-flow: row wrap;
		}
		@media screen and (max-width: 650px) {
			justify-content: center;
		}
	}
	&__card {
		margin: 10px;
		border-radius: 0 45px 45px 45px;
		box-shadow: 0 14px 40px #b0bfce54;
		position: relative;
		width: 29%;
		// min-height: 490px;
		@media screen and (max-width: 950px) {
			width: 45%;
		}
		@media screen and (max-width: 650px) {
			width: 90%;
		}
		&:hover > &__icon {
			transform: translateY(-25px);
			transition: transform 350ms ease-out;
		}
		&:hover > &__content {
			transform: translateY(-55px);
			transition: transform 350ms ease-out;
		}

		&__image {
			height: 250px;
			border-radius: 0px 45px 0 0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		&__icon {
			background-position: center;
			background-repeat: no-repeat;
			background-size: 65%;
			width: 60px;
			height: 60px;
			border-radius: 100%;
			margin: -70px auto 0;
			z-index: 100;
			transition: transform 350ms ease-out;
		}
		&__content {
			background-color: white;
			border-radius: 0 45px 45px 45px;
			padding: 60px 0px 50px;
			margin: 0;
			transform: translateY(-30px);
			transition: transform 350ms ease-out;

			a {
				text-decoration: none;
				color: $greenBtn;
			}
			p {
				width: 85%;
				margin: 0 auto 22px;
				text-align: center;
				font-size: 16px;
				line-height: 22px;
				font-family: 'Fira-Reg';
				color: $navigation;
			}
			&__title {
				width: 85%;
				font-size: 26px;
				line-height: 32px;
				margin: 0 auto 25px;
				color: $navigation;
				font-family: 'Exo-Bold';
				font-weight: 600;
				text-align: center;
			}
		}

		&__card-button {
			width: 80%;
			border-radius: 45px;
			height: 55px;
			margin: 0 auto 35px;
			left: 0;
			right: 0;
			position: absolute;
			z-index: 999998;
			transform: translateY(-85px);
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
		}

		&__dropdown {
			width: 80%;
			border-radius: 45px;
			max-height: 55px;
			overflow: hidden;
			margin: 0 auto 35px;
			left: 0;
			right: 0;
			position: absolute;
			z-index: 998;
			transform: translateY(-85px);
			transition: max-height 300ms ease-in-out, border-radius 5ms 295ms ease;

			&__label {
				height: 56px;
				color: #fff;
				display: flex;
				align-items: center;
				padding: 0 20px;
				cursor: pointer;
				&::after {
					content: url('../images/icons/dwn-arrow.svg');
					width: 12px;
					margin-left: auto;
				}
			}

			&__option {
				color: #fff;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				text-decoration: none;
				&:hover {
					filter: brightness(1.2) !important;
				}
			}
		}
	}
}

.drop-down-open {
	transition: max-height 300ms ease-out, border-radius 5ms ease;
}

// TEXT / IMAGE ROSS
.text-img-blocks {
	@include wrapper;
	margin: 150px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	@media screen and (max-width: 1024px) {
		margin: 75px auto;
	}
	@media screen and (max-width: 768px) {
		margin: 50px auto;
		flex-direction: column-reverse !important;
	}
	.content-block,
	.img-block {
		width: 50%;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.img-block-bg {
		// background-position: center;
		// background-repeat: no-repeat;
		// background-size: cover;
		height: 450px;
		width: 50%;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
		&.text-right {
			border-radius: 30px 150px 30px 30px;
			@media screen and (max-width: 600px) {
				border-radius: 20px 90px 20px 20px;
			}
		}
		@media screen and (max-width: 1160px) {
			height: 420px;
		}
		@media screen and (max-width: 1024px) {
			height: 400px;
		}
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-bottom: 50px;
		}
		@media screen and (max-width: 600px) {
			height: 350px;
			border-radius: 90px 20px 20px 20px;
		}
		@media screen and (max-width: 525px) {
			height: 275px;
		}
	}
	.img-block {
		img {
			width: 100%;
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 50px;
		}
	}
	.content-block {
		padding-right: 10%;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
		&.text-right {
			padding-right: 0;
			padding-left: 10%;
			@media screen and (max-width: 768px) {
				padding: 0;
			}
		}

		h2 {
			font-size: 38px;
			line-height: 40px;
			font-family: 'Exo-Bold';
			color: $navigation;
			margin-bottom: 25px;
			@media screen and (max-width: 1440px) {
				font-size: 40px;
				line-height: 50px;
			}
			@media screen and (max-width: 1024px) {
				font-size: 30px;
				line-height: 40px;
			}
		}
		li,
		p {
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 25px;
			font-family: 'Fira-Reg';
			color: $navigation;
			@media screen and (max-width: 1024px) {
				font-size: 15px;
				line-height: 22px;
			}
		}
		li {
			padding-left: 30px;
		}
		li {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 11px;
				height: 2px;
				width: 12px;
			}
			&:after {
				content: '';
				position: absolute;
				left: 5px;
				top: 6px;
				height: 12px;
				width: 2px;
			}
		}
		a {
			overflow: hidden;
		}
		.btn {
			overflow: hidden;
			font-size: 16px;
			line-height: 24px;
			cursor: pointer;
			font-family: Exo-Bold;
			color: #fff;
			text-align: center;
			margin-top: 15px;
			img {
				position: absolute;
				left: -30px;
				top: 0px;
				width: 60px;
			}
			&:hover {
				img {
					transition: 1.5s;
					transform: rotate(180deg);
				}
			}
		}
	}
}
.right-rad {
	border-radius: 0 30px 30px 30px !important;
	@media (max-width: 768px) {
		border-radius: 30px !important;
	}
}
.left-rad {
	border-radius: 30px 0 30px 30px !important;
	@media (max-width: 768px) {
		border-radius: 30px !important;
	}
}
// READY TO PARTICIPATE
.y-bg-wave {
	margin: -50px auto -20px;
	z-index: 105;
	width: 100%;
}
.cards-three-col {
	background-color: $p382;
	margin: 0px auto;
	padding: 30px 50px 100px;
	@media screen and (max-width: 1024px) {
		padding: 20px 150px 60px;
	}
	@media screen and (max-width: 700px) {
		padding: 10px 25px 50px;
	}
	.cards-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: stretch;
		@media screen and (max-width: 1024px) {
			margin: 25px auto;
		}
		.header-content {
			margin: 0 auto;
			padding-bottom: 90px;
			width: 100%;
			@media screen and (max-width: 980px) {
				padding-bottom: 70px;
			}
		}
		.card {
			width: 31%;
			box-shadow: 0 14px 40px #bec9d254;
			border-radius: 45px 100px 45px 45px;
			margin: 50px 0;
			@media screen and (max-width: 1024px) {
				width: 100%;
				margin: 45px auto 75px;
			}
			.card-img {
				height: 325px;
				background-position: center bottom;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 45px 100px 0 0;
				&.img-short {
					height: 250px;
				}
				&.img-tall {
					height: 400px;
					@media screen and (max-width: 1440px) {
						height: 325px;
					}
					@media screen and (max-width: 1024px) {
						height: 275px;
					}
				}
			}
			.card-icon {
				width: 90px;
				height: 90px;
				position: absolute;
				margin-top: -90px;
				left: calc(50% - 45px);
				border-radius: 45px;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;

				@media screen and (max-width: 1250px) {
					width: 60px;
					height: 60px;
					left: calc(50% - 30px);
					margin-top: -73px;
				}
				img {
					width: 40px;
					@media screen and (max-width: 1250px) {
						width: 25px;
					}
				}
			}
			.card-content {
				background-color: #fff;
				text-align: center;
				display: flex;
				flex-direction: column;
				border-radius: 45px;
				margin-top: -42px;
				padding: 55px 45px 0;
				height: 345px;
				@media screen and (max-width: 1160px) {
					padding: 50px 25px 0;
				}
				@media screen and (max-width: 1024px) {
					padding: 55px 55px 0;
				}
				@media screen and (max-width: 980px) {
					padding: 65px 35px 0;
				}
				@media screen and (max-width: 700px) {
					padding: 50px 25px 0;
				}
				@media screen and (max-width: 550px) {
					height: 325px;
				}
				h2 {
					font-size: 28px;
					line-height: 32px;
					margin: 10px auto 25px;
					font-family: 'Exo-Bold';
					color: $navigation;
					@media screen and (max-width: 768px) {
						font-size: 40px;
						line-height: 44px;
					}
					@media screen and (max-width: 550px) {
						font-size: 28px;
						line-height: 32px;
					}
				}
				a {
					text-decoration: none;
					color: $greenBtn;
					font-size: 16px;
					line-height: 27px;
					font-family: 'Fira-Reg';
					@media screen and (max-width: 1250px) {
						font-size: 14px;
						line-height: 22px;
					}
				}
				p {
					font-size: 16px;
					line-height: 27px;
					font-family: 'Fira-Reg';
					color: $navigation;
					margin-bottom: 25px;
					@media screen and (max-width: 1250px) {
						font-size: 14px;
						line-height: 22px;
					}
				}
				.card-btn {
					margin: 10px auto;
					overflow: hidden;
					text-align: none;
					color: $white;
					position: absolute;
					bottom: 35px;
					left: 50%;
					transform: translateX(-50%);
					@media screen and (max-width: 650px) {
						bottom: 45px;
					}
					@media screen and (max-width: 360px) {
						bottom: 25px;
					}
					img {
						position: absolute;
						left: -30px;
						top: 0px;
						width: 60px;
					}
					&:hover {
						img {
							transition: 1.5s;
							transform: rotate(180deg);
						}
					}
				}
				.social-icons {
					display: flex;
					width: 280px;
					margin: 8px auto;
					justify-content: space-between;
					align-items: center;
					position: absolute;
					bottom: 35px;
					left: 50%;
					transform: translateX(-50%);
					@media screen and (max-width: 1300px) {
						width: 240px;
					}
					img {
						width: 55px;
						@media screen and (max-width: 1300px) {
							width: 50px;
						}
					}
					@media screen and (max-width: 1024px) {
						width: 285px;
						margin: 8px auto;
					}
					@media screen and (max-width: 650px) {
						bottom: 45px;
					}
					@media screen and (max-width: 400px) {
						width: 80%;
					}
					@media screen and (max-width: 360px) {
						bottom: 25px;
						width: 85%;
					}
				}
			}
		}
	}
}
.textBlockStyles {
	margin: 60px auto;
	max-width: 1400px;
	padding: 0 50px;
	@media screen and (max-width: 1024px) {
		padding: 0 40px;
	}
	@media screen and (max-width: 650px) {
		padding: 0 25px;
		margin: 40px auto;
	}
}
.textBlock {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Exo-Bold';
		color: $navigation;
		margin-bottom: 20px;
	}
	a {
		text-decoration: none;
		color: $greenBtn;
		font-family: 'Fira-Bold';
	}
	p {
		font-family: 'Fira-Reg';
		font-size: 16px;
		line-height: 27px;
		margin-bottom: 15px;
	}
	h1 {
		font-size: 50px;
		line-height: 57px;
		@media screen and (max-width: 1024px) {
			font-size: 40px;
			line-height: 47px;
		}
		@media screen and (max-width: 768px) {
			font-size: 35px;
			line-height: 42px;
		}
	}
	h2 {
		font-size: 40px;
		line-height: 47px;
		@media screen and (max-width: 1024px) {
			font-size: 30px;
			line-height: 37px;
		}
		@media screen and (max-width: 768px) {
			font-size: 28px;
			line-height: 35px;
		}
	}
	h3 {
		font-size: 36px;
		line-height: 43px;
		@media screen and (max-width: 1024px) {
			font-size: 28px;
			line-height: 35px;
		}
		@media screen and (max-width: 768px) {
			font-size: 24px;
			line-height: 31px;
		}
	}
	h4 {
		font-size: 32px;
		line-height: 39px;
		@media screen and (max-width: 1024px) {
			font-size: 26px;
			line-height: 33px;
		}
		@media screen and (max-width: 768px) {
			font-size: 22px;
			line-height: 29px;
		}
	}
	h5 {
		font-size: 26px;
		line-height: 33px;
		@media screen and (max-width: 1024px) {
			font-size: 22px;
			line-height: 29px;
		}
		@media screen and (max-width: 768px) {
			font-size: 18px;
			line-height: 25px;
		}
	}
	h6 {
		font-size: 22px;
		line-height: 29px;
		@media screen and (max-width: 1024px) {
			font-size: 18px;
			line-height: 25px;
		}
		@media screen and (max-width: 768px) {
			font-size: 16px;
			line-height: 23px;
		}
	}
	ul li {
		list-style: none;
		margin: 0 0 0.5em;
		overflow: hidden;
		position: relative;
		padding: 0 0 0 1.5em;
	}
	ul li::before {
		color: $footer;
		content: '\2022';
		font-size: 20px;
		left: 0.4em;
		line-height: 0;
		position: absolute;
		top: 0.4em;
	}
	ol {
		list-style-type: decimal;
	}
	ol li {
		display: list-item;
		list-style-position: inside;
		margin: 0 0 0.5em;
		position: relative;
		padding: 0 0 0 6px;
	}
	ol li::marker {
		padding-right: 10px;
	}
	ol,
	ul {
		margin-bottom: 20px;
		font-size: 16px;
		font-family: 'Fira-Reg';
		color: $navigation;
	}
	img {
		max-width: 300px;
		text-align: left;
		margin-bottom: 15px;
	}
}
.doubleText {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
	.halfBlock {
		width: 46%;
		@media screen and (max-width: 1024px) {
			width: 48%;
		}
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}
}
