@font-face {
	font-family: 'Exo-Bold';
	src: url('../fonts/Exo2-Bold.ttf');
	// font-display: swap;
}
@font-face {
	font-family: 'Exo-Med';
	src: url('../fonts/Exo2-Medium.ttf');
	// font-display: swap;
}
@font-face {
	font-family: 'Exo-XBold';
	src: url('../fonts/Exo2-ExtraBold.ttf');
	// font-display: swap;
}
@font-face {
	font-family: 'Fira-Bold';
	src: url('../fonts/FiraSans-Bold.ttf');
	// font-display: swap;
}
@font-face {
	font-family: 'Fira-Reg';
	src: url('../fonts/FiraSans-Regular.ttf');
	// font-display: swap;
}
@font-face {
	font-family: 'Fira-Light';
	src: url('../fonts/FiraSans-Light.ttf');
	// font-display: swap;
}
