.event-form {
	background-color: $gray;
	padding: 60px 0;
	.two-up {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: 500px) {
			flex-wrap: wrap;
		}
		.two-half {
			display: flex;
			flex-direction: column;
			width: 48%;
			@media screen and (max-width: 500px) {
				width: 100%;
			}
		}
	}
}

.submit-event,
.event-form,
.contact-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 600px;
	margin: 0 auto;
	background-color: $gray;
	padding: 60px 25px;
	h2 {
		font-size: 28px;
		line-height: 32px;
		color: $navigation;
		text-align: left;
		font-family: 'Exo-Bold';
		margin: 45px 0 25px;
	}
	.contact-h2 {
		text-align: center;
		margin: 0 0 25px;
	}
	h3 {
		font-size: 18px;
		line-height: 24px;
		color: $navigation;
		text-align: left;
		font-family: 'Exo-Bold';
		margin: 25px 0 15px;
	}
	.helper-text,
	span {
		font-size: 15px;
		line-height: 27px;
		font-family: 'Fira-Reg';
		color: $grayText;
		opacity: 0.7;
		margin: -5px 0 6px;
		@media screen and (max-width: 500px) {
			line-height: 22px;
		}
	}
	input[type='checkbox'],
	input[type='radio'] {
		margin-right: 10px;
	}
	input[name='streetAddress'],
	input[name='addressLine2'],
	input[name='postalCode'],
	input[name='website'] {
		width: 100%;
	}
	label {
		font-size: 16px;
		font-family: 'Exo-Med';
		line-height: 27px;
		color: $navigation;
		text-align: left;
		margin: 10px 10px 2px 0;
		@media screen and (max-width: 450px) {
			font-size: 18px;
			line-height: 18px;
		}
	}
	select {
		border: 1px solid #cdd1d4;
		border-radius: 5px;
		margin-bottom: 15px;
		padding: 8px;
		option {
			padding: 3px 0 3px 20px;
			font-size: 16px;
			font-family: 'Fira-Reg';
			line-height: 20px;
			&:selected {
				background-color: $greenBtn;
			}
		}
		&:focus {
			outline: none;
		}
	}
	input[type='text'],
	input[type='email'],
	input[type='textarea'],
	textarea {
		border: 1px solid #cdd1d4;
		border-radius: 5px;
		padding: 8px;
		font-size: 16px;
		font-family: 'Fira-Reg';
		line-height: 20px;
		color: $navigation;
		text-align: left;
		cursor: pointer;
		margin: 0 0 6px;
		&:focus {
			outline: none;
		}
		@media screen and (max-width: 450px) {
			line-height: 18px;
		}
	}
	textarea {
		min-height: 66px;
		resize: both !important;
		overflow-x: hidden;
		resize: horizontal;
		display: inline-block;
	}
	input[type='date'],
	input[type='datetime-local'] {
		width: 260px;
		margin: 2px 0 4px 0;
		padding: 8px;
		font-size: 16px !important;
		font-family: 'Fira-Reg' !important;
		border: 1px solid #cdd1d4;
		border-radius: 5px;
		color: #a0a0a0;
	}
	&:focus {
		outline: none;
	}

	.radio-buttons {
		width: 100%;
		margin: -5px auto 24px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.radio-label {
			width: 100%;
		}
		div {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-top: 4px;
			label {
				margin: 3px 10px 0 10px;
				font-size: 16px;
				line-height: 20px;
			}
			input {
				margin: 6px 0 0 8px;
			}
		}
	}
	.errors {
		li {
			color: $greenBtn;
			font-size: 18px;
			text-transform: uppercase;
			font-family: 'Exo-Bold';
		}
	}
	.radio-age {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 8px 0;
		label {
			margin: 0 0 0 12px;
		}
		// flex-direction: row;
	}
	button,
	button[type='submit'] {
		margin: 30px 0 0;
		font-family: 'Exo-Med';
		font-size: 16px;
		color: $white;
		padding: 17px 30px 19px 44px;
		border-radius: 12px;
		width: 280px;
		background-color: $greenBtn;
		cursor: pointer;
		img {
			position: absolute;
			left: -32px;
			top: 0px;
			width: 65px;
		}
		&:hover {
			img {
				transition: 1.5s;
				transform: rotate(180deg);
			}
		}
	}
}

.celebsite-wrap {
	display: none;
	.celebsite {
		display: flex;
		flex-direction: column;
	}
}

.add-date {
	margin: 5px 0;
	display: flex;
	flex-direction: column;
	.additional-btn {
		height: 35px;
		width: 240px;
		margin: 3px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background-color: $greenBtn;
		font-family: 'Exo-Med';
		overflow: hidden;
		font-size: 16px;
		color: $white;
		padding: 17px 30px;
		cursor: pointer;
		img {
			position: absolute;
			left: -22px;
			top: 0px;
			width: 45px;
		}
		&:hover {
			img {
				transition: 1.5s;
				transform: rotate(180deg);
			}
		}
	}
}

.contact-form {
	max-width: unset;
	width: 100%;
	display: block;
	justify-content: unset;
	.form-heading {
		text-align: center;
		max-width: 600px;
		margin: 0 auto;
		h2 {
			font-size: 50px;
			line-height: 57px;
			color: $navigation;
			font-family: 'Exo-Bold';
			margin-bottom: 17px;
			@media screen and (max-width: 800px) {
				font-size: 38px;
				line-height: 44px;
			}
		}
		p {
			max-width: 400px;
			margin: 0 auto;
			font-size: 16px;
			line-height: 23px;
			color: $navigation;
			font-family: 'Fira-Reg';
		}
	}
	.form-wrapper {
		max-width: 500px !important;
		margin: 0px auto;
		padding: 20px 0;
		form {
			width: 100%;
			.ff-form-errors,
			.ff-form-success {
				p {
					font-size: 16px;
					line-height: 23px;
					color: $greenBtn;
					font-family: 'Fira-Reg';
				}
			}
			.freeform-row {
				width: 100%;
				.freeform-column {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 0;
					margin: 0;
					p {
						font-size: 15px;
						line-height: 27px;
						font-family: 'Fira-Reg';
						color: $grayText;
						opacity: 0.7;
						margin: -5px 0 6px;
						@media screen and (max-width: 500px) {
							line-height: 22px;
						}
					}
					button {
						margin: 30px auto 0;
					}
					.ff-errors {
						margin: -12px 0 5px;
						li {
							font-size: 16px;
							line-height: 23px;
							color: $greenBtn;
							font-family: 'Fira-Reg';
						}
					}
				}
			}
		}
	}
}
