.event-wrapper {
	width: 100%;
	padding: 0px 50px 40px;
	background-color: $lightGreen;
	@media screen and (max-width: 1024px) {
		padding: 40px 40px;
	}
	@media screen and (max-width: 768px) {
		padding: 30px 25px;
	}
	@media screen and (max-width: 600px) {
		padding: 30px 20px;
	}
	.sorry-text {
		font-size: 16px;
		color: $navigation;
		font-family: 'Exo-Bold';
		padding: 0 2.5px;
		line-height: 24px;
	}
	.event-container {
		display: flex;
		flex-direction: column;
		max-width: 1250px;
		margin: 0 auto;
		.sorry-text {
			text-align: center;
			font-size: 16px;
			line-height: 26px;
			font-family: 'Fira-Reg';
		}
	}
	.events-pages {
		margin: 60px 0 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-family: 'Exo-Bold';
		a {
			color: $greenBtn;
			margin: 0 12px;
			text-decoration: none;
		}
		.current {
			color: $navigation;
		}
	}
}
.event-block {
	width: 100%;
	padding: 40px;
	display: flex;
	margin: 12px 0 24px;
	justify-content: space-between;
	align-items: flex-start;
	border-radius: 0 15px 15px 15px;
	background-color: $white;
	@media screen and (max-width: 1024px) {
		flex-wrap: wrap;
	}
	&__left {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 45%;
		@media screen and (max-width: 1024px) {
			width: 100%;
			order: 2;
			padding-top: 6px;
		}
		@media screen and (max-width: 600px) {
			flex-wrap: wrap;
		}
	}
	&__img {
		width: 200px;
		border-radius: 0 15px 15px 15px;
		margin-right: 18px;
		@media screen and (max-width: 600px) {
			width: 300px;
			margin-bottom: 12px;
		}
		@media screen and (max-width: 400px) {
			width: 100%;
			margin-bottom: 12px;
		}
	}
	&__qs {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-right: 8px;
		@media screen and (max-width: 1024px) {
			border: none;
		}
	}
	&__when {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 7px 0 7px;
		width: 100%;
		color: $navigation;
		@media screen and (max-width: 1280px) {
			flex-direction: column;
		}
		@media screen and (max-width: 1024px) {
			flex-direction: row;
		}
	}
	.optional {
		margin: 0;
	}
	&__ask {
		margin-right: 20px;
		font-family: 'Fira-Bold';
		font-size: 16px;
		line-height: 24px;
		width: 90px;
	}
	&__answer {
		font-family: 'Fira-Reg';
		font-size: 14px;
		line-height: 24px;
	}
	&__social {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		img {
			width: 30px;
			margin-right: 12px;
		}
	}
	&__right {
		width: 55%;
		padding-left: 18px;
		color: $navigation;
		@media screen and (max-width: 1024px) {
			width: 100%;
			order: 1;
			padding-left: 0px;
		}
		p {
			font-family: 'Fira-Reg';
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 6px;
		}
	}
	&__text {
		font-family: 'Fira-Bold';
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;
		width: 100%;
		display: flex;
		@media screen and (max-width: 600px) {
			flex-wrap: wrap;
		}
		p {
			font-family: 'Fira-Bold';
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 6px;
			@media screen and (max-width: 600px) {
				margin-bottom: 0px;
			}
		}
		.text-detail {
			font-family: 'Fira-Light';
			margin-left: 5px;
			overflow-wrap: break-word;
			@media screen and (max-width: 600px) {
				margin-left: 0px;
				width: 100%;
			}
		}
	}
}

/* The container must be positioned relative: */
.custom-select {
	position: relative;
	font-family: Arial;
}

.custom-select select {
	display: none; /*hide original SELECT element: */
}

.select-selected {
	background-color: $footer;
	height: 100%;
}

/* Style the arrow inside the select element: */
.select-selected:after {
	position: absolute;
	content: '';
	top: 18px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
	border-color: transparent transparent #fff transparent;
	top: 12px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
	color: #ffffff;
	padding: 8px 16px;
	border: 1px solid transparent;
	border-radius: 12px 12px;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: all 0.2 ease-in-out;
}

/* Style items (options): */
.select-items {
	position: absolute;
	background-color: $footer;
	top: 45px;
	left: 0;
	right: 0;
	z-index: 99;
	height: 400px;
	overflow-y: scroll;
}
.select-arrow-active {
	border-radius: 12px 12px 0 0;
}
/* Hide the items when the select box is closed: */
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

.cs-input {
	width: 0;
	height: 0;
	position: absolute;
	bottom: 0;
	left: 0;
}

.events-top {
	padding: 40px 50px;
	background-color: $lightGreen;
	@media screen and (max-width: 1024px) {
		padding: 40px;
	}
	@media screen and (max-width: 768px) {
		padding: 30px 25px 0px;
	}

	.events-top-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1250px;
		margin: 0 auto;
		.e-buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			@media screen and (max-width: 500px) {
				flex-wrap: wrap;
			}
			.custom-select {
				width: 350px;
				height: 45px;
				font-family: 'Fira-Reg';
				@media screen and (max-width: 768px) {
					width: 250px;
				}
				@media screen and (max-width: 500px) {
					width: 100%;
					margin-bottom: 30px;
				}
			}
			.event-numbers {
				font-size: 16px;
				color: $navigation;
				font-family: 'Exo-Bold';
			}
		}
	}
}
