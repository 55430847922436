.sorryText {
	display: none;
	text-align: center;
	font-size: 18px;
	line-height: 28px;
	font-family: 'Fira-Reg';
	width: 100%;
	@media screen and (max-width: 750px) {
		margin-bottom: 50px;
		font-size: 14px;
		line-height: 26px;
	}
}
.resources {
	padding: 40px 40px;
	width: 100%;
	background-color: $lightGreen;
	// @media screen and (max-width: 1024px) {
	// 	padding: 40px 40px;
	// }
	@media screen and (max-width: 768px) {
		padding: 50px 25px;
	}
	&__container {
		max-width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media screen and (max-width: 750px) {
			flex-wrap: wrap;
		}
	}
	&__filter {
		width: 215px;
		margin-right: 20px;
		@media screen and (max-width: 750px) {
			width: 100%;
			margin-right: 0px;
		}
	}
	&__filTop {
		background-color: #5b6670;
		border-radius: 20px 20px 0 0;
		color: $white;
		padding: 16px 20px 8px;
		display: flex;
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		@media screen and (max-width: 750px) {
			border-radius: 30px;
			padding: 16px 20px;
			cursor: pointer;
		}
		img {
			@media screen and (max-width: 768px) {
				width: 15px;
			}
		}
		p {
			font-size: 21px;
			line-height: 32px;
			margin-left: 12px;
			font-family: 'Exo-Bold';
			@media screen and (max-width: 768px) {
				font-size: 17px;
				line-height: 27px;
			}
		}
		&--clicked {
			border-radius: 20px 20px 0 0;
		}
	}
	&__filBody {
		background-color: $white;
		padding: 20px 20px 30px;
		width: 100%;
		border-radius: 0 0 20px 20px;
		@media screen and (max-width: 1275px) {
			padding: 20px 10px 30px 14px;
		}
		@media screen and (max-width: 750px) {
			display: none;
			&--clicked {
				display: block;
			}
		}
	}
	&__liHeading {
		font-family: 'Exo-Bold';
		color: $navigation;
		font-size: 18px;
		line-height: 32px;
		margin: 10px auto 12px;
		&--two {
			padding-top: 15px;
		}
	}
	&__liInput {
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		color: $navigation;
		p {
			margin-left: 8px;
			font-family: 'Fira-Reg';
			font-size: 14px;
			line-height: 26px;
		}
	}

	&__checked {
		height: 15px;
		width: 15px;
		border-radius: 2px;
		border: 1px solid #9ba5af;
		background-color: $greenBtn;
		img {
			display: block;
		}
	}
	&__checkbox {
		height: 15px;
		width: 15px;
		border-radius: 2px;
		border: 1px solid #9ba5af;
		background-color: $white;
		img {
			display: none;
		}
	}
	&__resources {
		width: 80%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		a {
			width: 49%;
			text-decoration: none;
			@media screen and (max-width: 750px) {
				width: 100%;
			}
		}
		@media screen and (max-width: 750px) {
			width: 100%;
			margin-top: 30px;
		}
	}

	&__block {
		width: 100%;
		background-color: $white;
		margin-bottom: 25px;
		border-radius: 0 16px 16px 16px;
		height: auto;
		// @media screen and (max-width: 1280px) {
		// 	height: auto;
		// }
	}
	&__blockFlex {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 20px;
		@media screen and (max-width: 1280px) {
			flex-wrap: wrap;
		}
	}
	&__imgDiv {
		width: 205px;
		@media screen and (max-width: 1280px) {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__img {
		width: 194px;
		border-radius: 0 30px 30px 30px;
		height: 170px;
		object-fit: cover;
		@media screen and (max-width: 1280px) {
			width: 100%;
			height: 250px;
		}
		@media screen and (max-width: 1024px) {
			height: 180px;
		}
		@media screen and (max-width: 750px) {
			width: 100%;
			height: auto;
		}
	}
	&__text {
		width: 65%;
		padding-left: 25px;
		@media screen and (max-width: 1280px) {
			width: 100%;
			padding-left: 0px;
		}
	}
	&__resInfo {
		width: 100%;
		display: flex;
		margin-bottom: 8px;
		flex-wrap: wrap;
	}
	&__resType {
		background-color: $greenBtn;
		padding: 5px 10px;
		color: $white;
		border-radius: 6px;
		margin: 0 6px 6px 0;
		font-family: 'Fira-Reg';
		font-size: 12px;
		line-height: 23px;
	}
	&__title {
		font-family: 'Exo-Bold';
		font-size: 21px;
		line-height: 32px;
		color: $navigation;
		margin-bottom: 12px;
	}
	&__description {
		font-family: 'Fira-Reg';
		font-size: 14px;
		line-height: 21px;
		color: $navigation;
	}
}
