// DEFAULT SLIDER BUTTON / DOT STYLES
.prev-arrow,
.next-arrow {
	@include vertical-center;
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center;
	border-radius: 32px;
	background-color: #283840;
	width: 64px;
	height: 64px;
	position: absolute;
	cursor: pointer;
	z-index: 1;
	@media screen and (max-width: 1024px) {
		width: 50px;
		height: 50px;
		background-size: 12px;
	}
}
.prev-arrow {
	background-image: url(../img/icons/slider-arrow-prev.svg);
	left: 0;
}
.next-arrow {
	background-image: url(../img/icons/slider-arrow-next.svg);
	right: 0;
}

.slick-dots {
	li {
		display: inline;
		margin: 0 10px;
		button {
			opacity: 0.25;
			font-size: 0;
			width: 12px;
			height: 12px;
			border-radius: 8px;
			cursor: pointer;
			@media screen and (max-width: 640px) {
				width: 10px;
				height: 10px;
			}
		}
	}
	.slick-active {
		button {
			opacity: 1;
		}
	}
}

// EVENT HIGHLIGHTS SLIDER

.card-slider-full {
	padding: 150px 0 150px;
	background: $greenBtn;
	text-align: center;
	overflow: hidden;
	margin-bottom: -20px;
	@media screen and (max-width: 640px) {
		padding: 75px 0;
	}
	.slick-list .slick-track {
		display: flex;
	}
	.gear-desk,
	.gear-tab,
	.gear-mob {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	.gear-desk {
		@media screen and (max-width: 1250px) {
			display: none;
		}
	}
	.gear-tab {
		display: none;
		@media screen and (max-width: 1250px) {
			display: block;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	.gear-mob {
		display: none;
		@media screen and (max-width: 768px) {
			display: block;
		}
	}
	.card-ind {
		position: absolute;
		top: 50%;
		transform: translateX(-50%);
		width: 50px;
		cursor: pointer;
		z-index: 500;
		@media screen and (max-width: 1100px) {
			width: 35px;
		}
		@media screen and (max-width: 450px) {
			width: 35px;
			top: auto;
			transform: none;
			bottom: 63px;
		}
	}
	.card-left {
		left: 6%;
		@media screen and (max-width: 1175px) {
			left: 50px;
		}
		@media screen and (max-width: 768px) {
			left: 30px;
		}
		@media screen and (max-width: 450px) {
			left: 25px;
		}
	}
	.card-right {
		right: 2%;
		@media screen and (max-width: 1175px) {
			right: 10px;
		}
		@media screen and (max-width: 768px) {
			right: 0px;
		}
		@media screen and (max-width: 768px) {
			right: 0px;
		}
		@media screen and (max-width: 450px) {
			right: 23px;
		}
	}

	#bottom-wave {
		left: 0;
		right: 0;
		bottom: -2px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
	#top-wave {
		left: 0;
		right: 0;
		top: -2px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
	.header-content {
		margin: 0 auto;
		color: #fff;
		max-width: 900px;
	}
	h1 {
		color: $white;
		font-size: 50px;
		line-height: 57px;
		font-family: 'Exo-Bold';

		@media screen and (max-width: 768px) {
			font-size: 40px;
			line-height: 47px;
		}
		@media screen and (max-width: 525px) {
			font-size: 30px;
			line-height: 37px;
		}
	}
	h3 {
		color: $white;
		font-size: 16px;
		line-height: 27px;
		font-family: 'Fira-Reg';
		padding: 0 20px;
		max-width: 750px;
		margin: 0 auto;
		text-align: center;
	}

	.card-slider-full-container {
		// @include wrapper;
		margin: 50px auto 0;
		@media screen and (max-width: 1920px) {
			max-width: none;
			width: 100%;
		}
		.prev-arrow,
		.next-arrow {
			margin-top: -32px;
			@media screen and (max-width: 1024px) {
				background-color: transparent;
				margin-top: -75px;
			}
			@media screen and (max-width: 768px) {
				width: 45px;
				height: 45px;
			}
		}
		.prev-arrow {
			@media screen and (max-width: 1920px) {
				left: 10%;
			}
			@media screen and (max-width: 1720px) {
				left: 5%;
			}
			@media screen and (max-width: 1024px) {
				left: 0;
			}
		}
		.next-arrow {
			@media screen and (max-width: 1920px) {
				right: 10%;
			}
			@media screen and (max-width: 1720px) {
				right: 5%;
			}
			@media screen and (max-width: 1024px) {
				right: 0;
			}
		}
		.slick-slide {
			// width: 100% !important;
		}
		.slide {
			margin-bottom: 50px;
			max-width: 1120px;
			width: 80%;
			@media screen and (max-width: 1440px) {
				max-width: 950px;
			}
			.slide-inner {
				display: flex;
				@media screen and (max-width: 1024px) {
					flex-direction: column;
				}
			}
			.slide-img {
				margin-right: -42px;
				border-radius: 45px 0 0 45px;
				width: 100%;
				width: calc(100% - 438px);
				height: 600px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

				@media screen and (max-width: 1024px) {
					width: 75%;
					margin: 0 auto;
					background-position: center;
					border-radius: 45px 45px 0 0;
					height: 300px;
				}
				@media screen and (max-width: 525px) {
					height: 225px;
				}
				@media screen and (max-width: 450px) {
					width: 90%;
				}
			}
			.slide-content-container {
				border-radius: 45px;
				background-color: $white;
				width: 480px;
				height: 600px;
				@media screen and (max-width: 1024px) {
					margin: -42px auto 0;
					width: 75%;
					height: auto;
				}
				@media screen and (max-width: 450px) {
					width: 90%;
				}
				.slide-content {
					@include vertical-center;
					text-align: left;
					margin: 0 75px;
					@media screen and (max-width: 1024px) {
						top: 0;
						margin: 0;
						padding: 50px;
						transform: none;
						text-align: center;
					}
					@media screen and (max-width: 525px) {
						padding: 50px 30px;
					}
					.slide-loc {
						margin-top: 10px;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-family: 'Exo-Bold';
						color: $navigation;
						font-size: 21px;
						line-height: 32px;
						img {
							margin-right: 8px;
						}
						@media screen and (max-width: 1025px) {
							justify-content: center;
						}
					}
					h2 {
						font-size: 38px;
						line-height: 40px;
						font-family: 'Exo-Bold';
						color: $navigation;
						@media screen and (max-width: 1025px) {
							font-size: 32px;
							line-height: 36px;
						}
						@media screen and (max-width: 525px) {
							font-size: 24px;
							line-height: 34px;
						}
					}
					p {
						font-size: 18px;
						color: $navigation;

						line-height: 27px;
						font-family: 'Fira-Reg';
						margin: 25px 0 40px;
						@media screen and (max-width: 640px) {
							font-size: 16px;
							line-height: 25px;
							margin: 25px 0;
						}
					}
					a {
						font-size: 16px;
						line-height: 32px;
						font-family: 'Exo-Bold';
						color: $greenBtn;
						text-decoration: none;
						border-bottom: 1px solid $greenBtn;
					}

					.prev-price {
						text-decoration: line-through !important;
						font-size: 16px;
						margin-top: 60px !important;
						margin-bottom: 0;
					}

					.current-price {
						font-size: 20px;
						margin-top: 0px;
					}
				}
			}
		}
	}
}

// TABBED SLIDER
.nav-slider-tabs {
	margin: 150px 0;
	overflow: hidden;
	@media screen and (max-width: 1024px) {
		margin: 75px 0;
	}
	.header-content {
		margin: 0 auto;
		max-width: 900px;
		h1 {
			font-size: 67px;
			line-height: 78px;
			color: $navigation;
			font-family: 'Exo-Bold';
			padding: 0 50px;
			@media screen and (max-width: 1150px) {
				font-size: 55px;
				line-height: 65px;
			}
			@media screen and (max-width: 768px) {
				font-size: 35px;
				line-height: 40px;
				padding: 0 20px;
			}
		}
		h3 {
			font-size: 21px;
			line-height: 32px;
			color: $navigation;
			font-family: 'Exo-Med';
			padding: 0 50px;
			max-width: 850px;
			a {
				text-decoration: none;
				color: $greenBtn;
			}
			@media screen and (max-width: 768px) {
				font-size: 17px;
				line-height: 26px;
				padding: 0 20px;
			}
		}
	}

	.nav-slider-tabs-container {
		@include wrapper;
		margin: 100px auto;
		background-color: $footer;
		display: flex;
		border-radius: 45px;
		box-shadow: 0 14px 40px #b0bfce54;
		@media screen and (max-width: 1300px) {
			margin: 50px auto;
		}
		@media screen and (max-width: 768px) {
			background-color: transparent;
			box-shadow: none;
			flex-direction: column;
		}
		.mobile-tabs-container {
			display: none;
			background-color: #fff;

			margin-bottom: 25px;
			z-index: 1;

			@media screen and (max-width: 768px) {
				display: block;
			}
			.dropdown {
				background-color: $white;
				padding: 15px 0px;
				border-radius: 5px;
				border: 1px solid $navigation;
				color: $navigation;
				height: auto;
				p {
					padding: 4px 25px;
				}
				.open-arrow {
					position: absolute;
					top: 23px;
					right: 35px;
				}
			}
			&:hover {
				.dropdown-content {
					display: block;
				}
			}
			.dropdown-content {
				display: none;
				width: 100%;
				height: auto;
				transition: 1s ease-in-out;
				z-index: 50;
				// padding: 10px 15px;
				background-color: $white;
				color: $navigation;
				ul {
					width: 100% !important;
					height: auto;
					padding: 0;
				}
				li {
					padding: 10px 25px;
					width: 100% !important;
					height: auto;
					z-index: 55;
					&:hover {
						color: $white;
						background-color: $navigation;
					}
				}
				.slick-list,
				.slick-track,
				.slick-slide {
					height: auto !important;
					width: 100% !important;
				}
			}
		}
		.slider-tabs-container {
			width: 350px;
			padding: 40px 0 40px 40px;
			@media screen and (max-width: 1560px) {
				width: 300px;
			}
			@media screen and (max-width: 1300px) {
				width: 250px;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
				padding: 0;
				display: none;
				margin-top: 0;
			}
			ul {
				li {
					font-size: 16px;
					line-height: 21px;
					color: #fff;
					font-family: 'Exo-Bold';
					padding: 25px;
					margin: 20px 0;
					border-radius: 10px 0 0 10px;
					transition: all 0.25s ease-out;
					cursor: pointer;
					@media screen and (max-width: 1300px) {
						margin: 10px 0;
						padding: 20px;
						font-size: 16px;
					}
					&:hover {
						color: $greenBtn;
						background-color: $white;
					}
				}
			}
			.slick-slide {
				border: 0;
			}
			.slick-current {
				li {
					background-color: #fff;
					background-color: $footer;
				}
			}
			.slick-track {
				transform: none !important;
			}
		}
		.slide-container {
			width: calc(100% - 350px);
			background-color: #fff;
			border-radius: 40px;
			padding: 60px 60px 60px 120px;
			@media screen and (max-width: 1560px) {
				width: calc(100% - 300px);
				padding: 65px;
			}
			@media screen and (max-width: 1300px) {
				width: calc(100% - 250px);
				padding: 40px;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
				background-color: transparent;
				padding: 0;
			}
			.slick-list,
			.slick-track,
			.slick-slide div {
				height: 100%;
				display: flex;
				@media screen and (max-width: 1300px) {
					height: auto;
				}
				// @media screen and (max-width: 768px) {
				// 	flex-direction: column-reverse;
				// }
			}
			.slide {
				display: flex !important;
				justify-content: space-between;
				@media screen and (max-width: 1300px) {
					flex-wrap: wrap;
					justify-content: flex-start;
				}
			}
			.slide-content {
				width: calc(100% - 425px);
				display: flex;
				flex-direction: column;
				width: 45%;
				padding: 100px 120px 100px 0;
				@media screen and (max-width: 1600px) {
					padding: 50px 60px 50px 0;
				}
				@media screen and (max-width: 1300px) {
					width: 100%;
					height: auto;
				}
				@media screen and (max-width: 768px) {
					padding: 20px 20px 30px 0;
				}
				h2 {
					font-size: 28px;
					line-height: 32px;
					font-family: 'Exo-Bold';
					color: $navigation;
					margin-bottom: 15px;
					@media screen and (max-width: 1300px) {
						font-size: 24px;
						line-height: 28px;
					}
				}
				p {
					color: $navigation;
					font-family: 'Fira-Reg';
					margin-bottom: 15px;
					font-size: 16px;
					line-height: 27px;
					@media screen and (max-width: 1300px) {
						font-size: 15px;
						line-height: 22px;
					}
				}
				.btn {
					font-size: 16px;
					line-height: 24px;
					font-family: 'Exo-Bold';
					color: $white;
					text-align: center;
					overflow: hidden;
					margin-top: 15px;
					// @media screen and (max-width: 1300px) {
					// padding: 14px 30px 14px 40px;
					// }
					img {
						position: absolute;
						left: -32px;
						top: 0px;
						width: 65px;
					}
					&:hover {
						img {
							transition: 1.5s;
							transform: rotate(180deg);
						}
					}
				}
			}
			.slide-img {
				width: 425px;
				// width: 45%;
				height: 240px;
				border-radius: 0 20px 20px 20px;
				background-position: center bottom;
				background-repeat: no-repeat;
				background-size: cover;
				@media screen and (max-width: 1300px) {
					width: 100%;
					margin-top: 25px;
					min-height: 275px;
				}
				@media screen and (max-width: 960px) {
					min-height: 260px;
				}
				@media screen and (max-width: 768px) {
					margin: 0 0 25px 0;
					min-height: 300px;
				}
			}
		}
	}
}
