$tablet-width: 768px;
$desktop-width: 1025px;

@mixin mobile {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin vertical-center {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin wrapper {
	max-width: 1500px;
	margin: 0 auto;
	width: 90%;
}

%clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
