@import 'reset';
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'modules';
@import 'sliders';
@import 'forms';
@import 'events';
@import 'resources';

.twitter-feed {
	background-color: #eff8f6;
	padding: 100px 25px;
	.eui-widget-title,
	.es-widget-title a {
		text-decoration: none !important;
		font-size: 55px !important;
		font-family: 'Exo-Bold' !important;
		color: $navigation;
		margin-bottom: 30px;
		@media screen and (max-width: 1024px) {
			font-size: 40px !important;
		}
		@media screen and (max-width: 768px) {
			font-size: 35px !important;
		}
	}
	.elfsight-app-3155c635-8afe-4862-b622-798f2ba084ea {
		margin: 0px auto;
	}
	.top-wave {
		left: 0;
		right: 0;
		top: -2px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
	.eapps-twitter-feed-posts-item-text,
	.eapps-twitter-feed-posts-item-user-name,
	.eapps-twitter-feed-posts-item-actions-item-share,
	.eapps-twitter-feed-posts-item-actions,
	.eapps-twitter-feed-posts-item-retweet-status-label,
	.eapps-twitter-feed-posts-show-more {
		font-family: 'Fira-Reg';
	}
	.eapps-twitter-feed-posts-item-text a {
		color: $greenBtn;
	}
	.eapps-twitter-feed-posts-item-inner {
		border-radius: 18px;
	}
	.eapps-twitter-feed-posts-show-more {
		background-color: #00b189;
		color: #fff;
		width: 280px;
		height: 54px;
		font-weight: 600;
		border-radius: 8px;
		transition: 0.3s ease-in-out all;
		&:hover {
			color: $greenBtn;
			background-color: $white;
			border: 1px solid $greenBtn;
		}
	}
	.eapps-twitter-feed-inner {
		background-color: #eff8f6;
		overflow: hidden;
	}
}
#eapps-twitter-feed-1.eapps-twitter-feed-color-scheme--custom
	.eapps-twitter-feed-header-inner,
#eapps-twitter-feed-1.eapps-twitter-feed-color-scheme--custom
	.eapps-twitter-feed-posts {
	background-color: rgb(239, 248, 246);
}
.btn {
	width: 216px;
	padding: 17px 30px 19px 44px;
	color: $white;
	background-color: $greenBtn;
	border-radius: 8px;
	text-decoration: none;
	overflow: hidden;
}
.bg-wave {
	left: 0;
	right: 0;
	top: -100px;
	z-index: 110;
	position: absolute;
	width: 100%;
	@media screen and (max-width: 1050px) {
		top: -65px;
	}
	@media screen and (max-width: 750px) {
		top: -45px;
	}
	@media screen and (max-width: 520px) {
		top: -15px;
	}
}
.mobile-nav {
	// width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	width: 0px;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	z-index: 16000005;
	// display: none;
	overflow: hidden !important;
	transition: all 200ms cubic-bezier(0.365, 0.74, 0.315, 0.995); /* custom */
	transition-timing-function: cubic-bezier(0.365, 0.74, 0.315, 0.995);
	.mobile-content {
		height: 100%;
		width: 450px;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 0 0 90px;
		background-color: $white;
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: 650px) {
			width: 90%;
		}
		.mobile-top {
			width: 100%;
			height: 60vh;
			padding: 27px 30px 30px 42px;
			overflow: hidden;
			.close-nav {
				position: absolute;
				top: 27.5px;
				right: 30px;
				cursor: pointer;
			}
			.mob-gear {
				position: absolute;
				top: -85px;
				left: -60px;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				margin-top: 120px;
				li {
					width: 100%;
					font-size: 25px;
					line-height: 28px;
					font-family: 'Exo-Med';
					margin-bottom: 23px;
					a {
						text-decoration: none;
						color: $navigation;
						&:hover {
							color: $greenBtn;
						}
					}
					.navItem-active {
						color: $greenBtn;
					}
				}
				.e-submit {
					width: 210px;
					height: 55px;
					border-radius: 10px;
					background-color: $p382;
					margin: 30px 0 0 0;
					display: flex;
					font-family: 'Exo-Bold';
					font-size: 16px;
					justify-content: center;
					align-items: center;
					a {
						height: 100%;
						width: 100%;
						text-decoration: none;
						color: $navigation;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
		.mobile-bottom {
			background-color: $greenBtn;
			color: $white;
			height: 40vh;
			width: 100%;
			padding: 46px 25px 45px 42px;
			border-radius: 0 0 0 90px;
			.green-wave {
				position: absolute;
				top: -2px;
				left: 0;
				width: 100%;
			}
			.mob-bottom-links {
				display: flex;
				flex-wrap: wrap;
				li {
					font-size: 16px;
					font-family: 'Exo-Med';
					margin-bottom: 20px;
					width: 100%;
					a {
						text-decoration: none;
						color: $white;
						&:hover {
							color: $navigation;
						}
					}
				}
			}
			.social-links {
				display: flex;
				margin-top: 20px;
				li {
					width: auto;
					margin-right: 16px;
					img {
						width: 40px;
					}
				}
			}
		}
	}
}
nav {
	width: 100%;
	padding: 25px 50px;
	position: sticky;
	top: 0;
	z-index: 1000;
	background-color: $navigation;
	height: 105px;
	.nav {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.nrw-nav {
			order: 1;
		}
		ul {
			order: 2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			li {
				list-style-type: none;
				margin: 0 52px 0 0;
				color: $white;
				font-family: 'Exo-Med';
				a {
					text-decoration: none;
					color: $white;
					&:hover {
						color: $greenBtn;
					}
				}
				.navItem-active {
					color: $greenBtn;
				}
			}
			.e-submit {
				width: 210px;
				height: 55px;
				border-radius: 10px;
				background-color: $p382;
				margin: 0;
				display: flex;
				font-family: 'Exo-Bold';
				font-size: 16px;
				justify-content: center;
				align-items: center;
				a {
					height: 100%;
					width: 100%;
					text-decoration: none;
					color: $navigation;
					display: flex;
					justify-content: center;
					align-items: center;
					&:hover {
						color: $white;
					}
				}
			}
		}
		.hamburger {
			display: none;
			width: 41px;
			cursor: pointer;
		}
	}
}
.homebgImg {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
}
.showTablet,
.hideMobile {
	display: none;
}
@media screen and (max-width: 1280px) {
	.showTablet {
		display: block;
	}
	.leaveDesk {
		display: none;
	}
}
@media screen and (max-width: 450px) {
	.showMobile {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	.leaveTablet,
	.leaveDeskMobile {
		display: none;
	}
}

.home-header {
	width: 100%;
	z-index: 5;
	height: 600px;
	padding: 0 90px;
	@media screen and (max-width: 1300px) {
		padding: 0 50px 0 45px;
		height: 620px;
	}
	@media screen and (max-width: 600px) {
		padding: 0 25px;
		height: 660px;
	}
	&__content {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 115px 0 0 50px;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		align-content: flex-start;
		@media screen and (max-width: 1370px) {
			padding: 115px 0 0;
		}
		@media screen and (max-width: 600px) {
			align-content: center;
			flex-direction: column;
		}
	}
	&__img {
		width: 700px;
		height: 455px;
		object-fit: cover;
		position: absolute;
		right: 70px;
		top: 40px;
		z-index: 5;
		@media screen and (max-width: 1400px) {
			width: 600px;
			height: 390px;
			top: 85px;
		}
		@media screen and (max-width: 1200px) {
			width: 520px;
			height: 340px;
		}
		@media screen and (max-width: 1150px) {
			top: 95px;
			right: 20px;
			width: 440px;
			height: 290px;
		}
		@media screen and (max-width: 950px) {
			top: 155px;
			right: 20px;
			width: 360px;
			height: 240px;
		}
		@media screen and (max-width: 800px) {
			top: 220px;
			right: 20px;
			width: 250px;
			height: 160px;
		}
		@media screen and (max-width: 650px) {
			top: 220px;
			right: 20px;
			width: 215px;
			height: 140px;
		}
		@media screen and (max-width: 650px) {
			top: 45px;
			right: 50%;
			transform: translateX(50%);
			width: 80%;
			height: auto;
		}
		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}
	&__text {
		z-index: 10;
		width: 570px;
		// padding-left: 90px;
		@media screen and (max-width: 1150px) {
			width: 430px;
		}
		@media screen and (max-width: 650px) {
			width: 325px;
			margin: 130px auto 0;
		}
		@media screen and (max-width: 650px) {
			width: 450px;
			margin: 190px auto 0;
			text-align: center;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
			margin: 190px auto 0;
			padding: 0 20px;
			text-align: center;
		}
		@media screen and (max-width: 420px) {
			margin: 145px auto 0;
			padding: 0px;
		}
	}
	&__h1 {
		color: $white;
		font-size: 80px;
		line-height: 78px;
		font-family: 'Exo-Bold';
		@media screen and (max-width: 1150px) {
			font-size: 60px;
			line-height: 60px;
		}
		@media screen and (max-width: 650px) {
			font-size: 42px;
			line-height: 43px;
		}
		@media screen and (max-width: 600px) {
			margin-bottom: 20px;
		}
		@media screen and (max-width: 365px) {
			font-size: 38px;
			line-height: 40px;
		}
	}
	&__desc {
		color: $white;
		font-family: 'Exo-Med';
		font-size: 23px;
		line-height: 32px;
		margin: 0 0 17px 0;
		@media screen and (max-width: 1180px) {
			width: 315px;
		}
		@media screen and (max-width: 650px) {
			margin: 0 auto;
			width: 90%;
		}
		@media screen and (max-width: 600px) {
			padding: 0;
			width: 90%;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}
	&__date {
		color: $p382;
		font-size: 40px;
		line-height: 53px;
		font-family: 'Exo-Bold';
		@media screen and (max-width: 600px) {
			font-size: 30px;
		}
	}
}
.twoButtons {
	width: 100%;
	z-index: 50;
	margin: -65px 0 0;
	display: flex;
	justify-content: center;
	align-self: center;
	overflow: hidden;
	@media screen and (max-width: 1200px) {
		margin: -65px 0 0;
		padding: 0 45px;
		flex-wrap: wrap;
	}
	@media screen and (max-width: 650px) {
		margin: -65px 0 0;
		padding: 0 25px;
		flex-wrap: wrap;
	}
	&__icon {
		position: absolute;
		width: 100px;
		left: -30px;
		top: 50%;
		transform: translateY(-50%);
		@media screen and (max-width: 650px) {
			width: 70px;
			top: 0px;
			transform: none;
		}
	}
	&__block {
		height: 200px;
		width: 527px;
		margin-right: 45px;
		border-radius: 0px 30px 30px 30px;
		background-color: blue;
		overflow: hidden;
		color: $white;
		@media screen and (max-width: 1220px) {
			width: 80%;
			// height: 176px;
			margin: 0 0 25px 0;
		}
		@media screen and (max-width: 1000px) {
			width: 100%;
		}
		@media screen and (max-width: 650px) {
			height: 240px;
		}
		&--first {
			background-color: $footer;
		}
		&--second {
			background-color: $lightBlue;
		}
	}
	&__btn {
		position: absolute;
		top: 33%;
		transform: translateY(-50%);
		right: 60px;
		cursor: pointer;
		@media screen and (max-width: 820px) {
			top: 25%;
			right: 25px;
			width: 30px;
		}
	}
	&__a {
		width: 100%;
		height: 100%;
		color: $white;
		display: flex;
		padding: 0 60px 0 115px;
		flex-direction: column;
		justify-content: center;
		text-decoration: none !important;
		@media screen and (max-width: 650px) {
			padding: 0 57px 0 34px;
		}
		@media screen and (max-width: 450px) {
			padding: 0 37px 0 34px;
		}
	}
	&__h2 {
		font-size: 28px;
		line-height: 32px;
		margin-bottom: 12px;
		font-family: 'Exo-Bold';
		@media screen and (max-width: 950px) {
			font-size: 28px;
			line-height: 32px;
			margin-bottom: 14px;
		}
		@media screen and (max-width: 650px) {
			font-size: 25px;
			line-height: 26px;
			margin-bottom: 19px;
		}
	}
	&__desc {
		font-size: 14px;
		line-height: 23px;
		font-family: 'Fira-Reg';
		width: 250px;
		@media screen and (max-width: 1200px) {
			width: 380px;
		}
		@media screen and (max-width: 1200px) {
			width: 100%;
		}
		@media screen and (max-width: 650px) {
			font-size: 15px;
			line-height: 23px;
		}
	}
}
.header {
	width: 100%;
	&__top {
		width: 100%;
		padding: 60px 90px;
		text-align: center;
		color: $white;
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		@media screen and (max-width: 600px) {
			padding: 60px 45px;
		}
		@media screen and (max-width: 600px) {
			padding: 45px 20px;
		}
		&--tall {
			height: 420px;
			@media screen and (max-width: 1080px) {
				height: 375px;
			}
			@media screen and (max-width: 768px) {
				height: 275px;
			}
		}
		&--short {
			height: 300px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media screen and (max-width: 1080px) {
				height: 260px;
			}
		}
	}
	&__bgImg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__title {
		max-width: 780px;
		margin: 0 auto;
		font-size: 67px;
		line-height: 78px;
		font-family: 'Exo-Bold';
		@media screen and (max-width: 1080px) {
			font-size: 47px;
			line-height: 57px;
		}
		@media screen and (max-width: 768px) {
			font-size: 32px;
			line-height: 46px;
		}
	}
	&__desc {
		max-width: 780px;
		margin: 0 auto;
		font-size: 23px;
		line-height: 35px;
		font-family: 'Exo-Med';
		@media screen and (max-width: 1080px) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	&__bottomImg {
		width: 237px;
		text-align: center;
		margin: -22px auto 0;
		@media screen and (max-width: 768px) {
			width: 180px;
		}
	}
	&__bottom {
		width: 100%;
		&--flex {
			display: flex;
			justify-content: center;
			align-items: flex-start;
		}
		@media screen and (max-width: 768px) {
			padding: 0 0 5px;
		}
	}
	&__bottomContainer {
		margin: -150px auto 0;
		max-width: 90%;
		height: 450px;
		background-color: transparent;
		padding: 50px;
		@media screen and (max-width: 768px) {
			margin: -70px auto 0;
			height: 260px;
		}
	}
	&__imgContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 30px;
		border-top-left-radius: 0;
		object-fit: cover;
		@media screen and (max-width: 768px) {
			border-radius: 30px;
		}
	}

	&__text {
		width: 52%;
		height: 100%;
		padding: 72px 52px;
		text-align: left;
		background-color: $white;
		color: $bodyText;
		border-radius: 30px;
		border-top-left-radius: 0;
		@media screen and (max-width: 1280px) {
			width: 60%;
		}
		@media screen and (max-width: 1050px) {
			padding: 50px 35px;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	&__h2 {
		font-size: 50px;
		line-height: 57px;
		margin-bottom: 17px;
		font-family: 'Exo-Bold';
		@media screen and (max-width: 1280px) {
			font-size: 32px;
			line-height: 38px;
		}
	}
	&__p {
		font-size: 21px;
		font-family: 'Exo-Med';
		line-height: 32px;
		@media screen and (max-width: 1280px) {
			font-size: 18px;
			line-height: 26px;
		}
	}
}

footer {
	background-color: $footer;
	padding: 51px 75px 47px;
	width: 100%;
	.footer {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.footer-logo {
			width: 253px;
			order: 1;
		}
		.page-links {
			order: 2;
			color: $white;
			li {
				margin-right: 50px;
				font-family: 'Exo-Med';
				a {
					text-decoration: none;
					color: #fff;
				}
			}
			.last-link {
				margin: 0;
			}
		}
		.social-links {
			order: 3;
			li {
				margin-right: 12px;
			}
			.last-link {
				margin: 0;
			}
		}
		.last-link {
			margin: 0;
		}
		.footer-links {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@media screen and (max-width: 1080px) {
	nav {
		padding: 25px 40px 25px 50px;
		height: 105px;
		.nav {
			.nrw-nav {
				order: 2;
				img {
					width: 236px;
				}
			}
			ul {
				order: 3;
				.nav-hide {
					display: none;
				}
				.e-submit {
					width: 162px;
				}
			}
			.hamburger {
				display: block;
				order: 1;
			}
		}
	}
	footer {
		padding: 65px 65px 68px;
		.footer {
			align-items: flex-end;
			flex-wrap: wrap;
			.footer-logo {
				width: 233px;
				order: 1;
			}
			.page-links {
				order: 3;
				width: 100%;
				margin-top: 45px;
				li {
					margin: 0;
				}
			}
			.social-links {
				order: 2;
			}
			.footer-links {
				align-items: flex-end;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	nav {
		height: 75px;
		padding: 20px 26px 20px 25px;
		.nav {
			.nrw-nav {
				order: 1;
				img {
					width: 167px;
				}
			}
			ul {
				display: none;
			}
			.hamburger {
				order: 2;
				width: 33px;
			}
		}
	}
	footer {
		padding: 62px 50px 72px;
		.footer {
			.footer-logo {
				width: 222px;
				order: 1;
			}
			.page-links {
				order: 2;
				width: 100%;
				margin-top: 45px;
				flex-direction: column;
				align-items: flex-start !important;
				li {
					margin-bottom: 28px;
				}
			}
			.social-links {
				order: 3;
				margin-top: 55px;
			}
			.footer-links {
				align-items: flex-end;
			}
		}
	}
}
